/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getFacetData($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 12, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      items {
        __typename
        uid
        sku
        name
        stock_status
        only_x_left_in_stock
        ... on ConfigurableProduct {
          configurable_options {
            attribute_code
            attribute_uid
            label
            position
            uid
            use_default
            values {
              label
              swatch_data {
                value
              }
              uid
            }
          }
        }
        thumbnail {
          url
          position
          disabled
          label
        }
        url_key
        product_label {
          name,
          color,
          text_color,
          text
        }
        url_rewrites {
          url
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        ... on GroupedProduct {
          items {
            product {
              sku
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;
