




















import { defineComponent } from '@nuxtjs/composition-api';

import DefaultLayout from './default.vue';
import FooterMinimal from "~/components/Footer/FooterMinimal.vue";

export default defineComponent({
  name: 'CheckoutLayout',
  components: {FooterMinimal},
  extends: DefaultLayout
});
