import { UseContextReturn } from '~/types/core';
import type { UseNewsletterUpdateSubscriptionParams} from '~/composables';
import { FetchResult } from '@apollo/client/core';

export const updateSubscriptionCommand = {
  execute: async (context: UseContextReturn, params: UseNewsletterUpdateSubscriptionParams): Promise<FetchResult<any>> => {
    return await context.app.$vsf.$magento.api.subscribeEmailToNewsletter({
      email: params.email,
    }, params?.customQuery ?? null, params?.customHeaders ?? null);
  },
};
