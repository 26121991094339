import { render, staticRenderFns } from "./TopBar.vue?vue&type=template&id=28b7bddc&scoped=true&"
import script from "./TopBar.vue?vue&type=script&lang=ts&"
export * from "./TopBar.vue?vue&type=script&lang=ts&"
import style0 from "./TopBar.vue?vue&type=style&index=0&id=28b7bddc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b7bddc",
  null
  
)

export default component.exports