















import { defineComponent } from "@nuxtjs/composition-api";
import { SfButton, SfTopBar } from "@storefront-ui/vue";
import { useTopBar } from "./useTopBar";
import contentBlocks from "~/components/ContentBlocks.vue";
export default defineComponent({
  components: {
    CurrencySelector: () => import("../CurrencySelector.vue"),
    StoreSwitcher: () => import("../StoreSwitcher.vue"),
    SfTopBar,
    SfButton,
    contentBlocks
  },
  data() {
    return {
      deferredPrompt: null
    }
  },
  setup() {
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    return {
      hasCurrencyToSelect,
      hasStoresToSelect,
    };
  },

  mounted() {
    if (process.browser) {
      window.addEventListener('beforeinstallprompt', (e) => {
        this.deferredPrompt = e;
      });
    }
  },

  methods: {
    downloadApp: async function () {
      if (process.browser) {
        if (this.deferredPrompt !== null) {
          this.deferredPrompt.prompt();
          const { outcome } = await this.deferredPrompt.userChoice;
          if (outcome === 'accepted') {
            this.deferredPrompt = null;
          }
        }
      }
    }
  },
});
