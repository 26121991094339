import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e9d1f6f4 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _4b1f8ac6 = () => interopDefault(import('../modules/blog/pages/Blog.vue' /* webpackChunkName: "" */))
const _1e857320 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _68d06374 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _e9a09cdc = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _29df039d = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _1a80b727 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _040e82ce = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _54c8d7f9 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _642b34f2 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _c914274a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _ba971a6e = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _2167360a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _08006305 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _8c20a16e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _f9899af2 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _21227bee = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _1b78c255 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _8113ddf8 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _63a49925 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _620f7d6c = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _4967cb94 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _c0a57678 = () => interopDefault(import('../modules/blog/pages/Post.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/eu",
    component: _e9d1f6f4,
    name: "home___eu"
  }, {
    path: "/eu/blog",
    component: _4b1f8ac6,
    name: "blog___eu"
  }, {
    path: "/eu/cart",
    component: _1e857320,
    name: "cart___eu"
  }, {
    path: "/eu/checkout",
    component: _68d06374,
    name: "checkout___eu",
    children: [{
      path: "billing",
      component: _e9a09cdc,
      name: "billing___eu"
    }, {
      path: "payment",
      component: _29df039d,
      name: "payment___eu"
    }, {
      path: "shipping",
      component: _1a80b727,
      name: "shipping___eu"
    }, {
      path: "thank-you",
      component: _040e82ce,
      name: "thank-you___eu"
    }, {
      path: "user-account",
      component: _54c8d7f9,
      name: "user-account___eu"
    }]
  }, {
    path: "/eu/Cms",
    component: _642b34f2,
    name: "Cms___eu"
  }, {
    path: "/eu/customer",
    component: _c914274a,
    meta: {"titleLabel":"My Account"},
    name: "customer___eu",
    children: [{
      path: "addresses-details",
      component: _ba971a6e,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___eu"
    }, {
      path: "my-newsletter",
      component: _2167360a,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___eu"
    }, {
      path: "my-profile",
      component: _08006305,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___eu"
    }, {
      path: "my-reviews",
      component: _8c20a16e,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___eu"
    }, {
      path: "my-wishlist",
      component: _f9899af2,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___eu"
    }, {
      path: "order-history",
      component: _21227bee,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___eu"
    }, {
      path: "addresses-details/create",
      component: _1b78c255,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___eu"
    }, {
      path: "/eu/reset-password",
      component: _8113ddf8,
      alias: "/customer/account/createPassword",
      name: "reset-password___eu"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _63a49925,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___eu"
    }, {
      path: "order-history/:orderId",
      component: _620f7d6c,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___eu"
    }]
  }, {
    path: "/eu/Home",
    component: _e9d1f6f4,
    name: "Home___eu"
  }, {
    path: "/eu/Page",
    component: _4967cb94,
    name: "Page___eu"
  }, {
    path: "/eu/blog/:identifier",
    component: _c0a57678,
    name: "post___eu"
  }, {
    path: "/eu/:slug+",
    component: _4967cb94,
    name: "page___eu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
