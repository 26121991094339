






























import {computed, defineComponent} from '@nuxtjs/composition-api';
import { SfInput, SfButton } from '@storefront-ui/vue';
import { useNewsletter } from '~/composables';

export default defineComponent({
  name: 'FooterNewsletter',
  components: {
    SfInput,
    SfButton
  },
  props: {
  },
  data() {
    return {
      email: ''
    }
  },
  setup() {
    const { loading, error, result, updateSubscription } = useNewsletter();
    const self = this;
    const subscribe = function(emailToSubscribe: string) {
      updateSubscription({email: emailToSubscribe});
    }
    const errorMessage = computed(() => error.value.updateSubscription?.message);
    const subscribed = computed(() => result.value);

    return {
      subscribe,
      loading,
      errorMessage,
      subscribed
    }
  }
});
