










































import { defineComponent } from "@nuxtjs/composition-api";
import {
  SfImage, SfMenuItem
} from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';
export default defineComponent({
  components: {
    SfImage,
    SfMenuItem
  },
  data() {
    return {
      payments: ['visa', 'mastercard', 'afterpay', 'laybuy', 'paypal']
    }
  },
  setup() {
    return {
      addBasePath,
    };
  },
});
