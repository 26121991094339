<template>
  <SfIcon
    class=""
    color="var(--c-primary)"
    viewBox="0 0 28 28"
    :coverage="1"
    icon="M4.65333 7.30667C4.29251 7.30667 4 7.01416 4 6.65333C4 6.29251 4.29251 6 4.65333 6H23.4995C23.8603 6 24.1528 6.29251 24.1528 6.65333C24.1528 7.01416 23.8603 7.30667 23.4995 7.30667H4.65333ZM4.65333 21.1272C4.29251 21.1272 4 20.8347 4 20.4738C4 20.113 4.29251 19.8205 4.65333 19.8205H23.4995C23.8603 19.8205 24.1528 20.113 24.1528 20.4738C24.1528 20.8347 23.8603 21.1272 23.4995 21.1272H4.65333ZM4 13.5636C4 13.9244 4.29251 14.2169 4.65333 14.2169H23.4995C23.8603 14.2169 24.1528 13.9244 24.1528 13.5636C24.1528 13.2028 23.8603 12.9103 23.4995 12.9103H4.65333C4.29251 12.9103 4 13.2028 4 13.5636Z"
  />
</template>
<script>
import {
  SfIcon,
} from '@storefront-ui/vue';
export default {
  name: "HamburgerIcon",
  components: { SfIcon }
};
</script>
