











import {
  defineComponent,
} from '@nuxtjs/composition-api';
import HeaderMobile from "~/components/Header/HeaderMobile.vue";
import HeaderDesktop from "~/components/Header/HeaderDesktop.vue";
export default defineComponent({
  components: {HeaderDesktop, HeaderMobile}

});
